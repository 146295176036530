<template>
  <v-card
    v-if="!loading"
    class="user-edit-info pa-sm-10 pa-3"
  >
    <v-card-title class="justify-start text-h5">
      <span v-if="action === 'create'">
        {{ t('Create New Tip') }}
      </span>
      <span v-if="action === 'update'">
        {{ t('Edit Tip Information') }}
      </span>
    </v-card-title>

    <v-card-text class="mt-5">
      <v-form
        ref="form"
        class="multi-col-validation"
        @submit.prevent="onSubmit"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="data.title"
              name="title"
              :label="t('Title')"
              :rules="[validators.required]"
              :error-messages="errorMessages.title"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="data.color"
              name="color"
              :label="t('Color')"
              :rules="[]"
              :error-messages="errorMessages.color"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <v-text-field
              v-model="data.url"
              name="url"
              :label="t('URL')"
              :rules="[validators.urlValidator]"
              :error-messages="errorMessages.url"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
          >
            <v-file-pond
              :controller.sync="pondController"
              name="image"
              disk="tips"
              max-files="1"
              class="w-100"
              :label="t('Image')"
              max-paralel-uploads="1"
              :allow-multiple="false"
              :errors="errorMessages.image"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-start mt-3"
          >
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :disabled="updating || pondController.uploading"
              :loading="updating || pondController.uploading"
            >
              {{ t('Submit') }}
            </v-btn>

            <v-btn
              v-if="resetForm"
              outlined
              color="secondary"
              class="me-3"
              :disabled="updating || pondController.uploading"
              :loading="updating || pondController.uploading"
              @click="resetForm"
            >
              {{ t('Discard') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { ref, onMounted } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline, no-unused-vars
import { mdiPlus, mdiUpdate, mdiEyeOutline, mdiDeleteOutline } from '@mdi/js'

// eslint-disable-next-line object-curly-newline, no-unused-vars
import { required, emailValidator, urlValidator, integerValidator } from '@core/utils/validation'

export default {
  name: 'TipForm',

  props: {
    action: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
    updating: {
      type: Boolean,
      default: false,
    },

    handleSubmit: {
      type: Function,
      required: true,
      default: () => {},
    },
    handleDiscard: {
      type: Function,
      default: null,
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const { t } = useUtils()

    const form = ref(null)

    const pondController = ref({
      files: [],
      uploading: false,
    })

    onMounted(() => {
      if (props.data.image && props.data.thumbUrl) {
        pondController.value.files = [{
          source: props.data.image,
          options: {
            type: 'local',
          },
        }]
      }
    })

    const onSubmit = () => {
      const isFormValid = form.value.validate()
      if (!isFormValid) return
      const formData = new FormData(form.value.$el)
      props.handleSubmit(formData)
    }

    const resetForm = () => {
      form.value.reset()
      form.value.resetErrorBag()
      form.value.resetValidation()

      // errorMessages.value = []

      props.handleDiscard()
    }

    return {
      t,

      icons: {
        mdiPlus,
        mdiUpdate,
        mdiEyeOutline,
        mdiDeleteOutline,
      },

      validators: {
        required,
        urlValidator,
        emailValidator,
        integerValidator,
      },

      form,
      onSubmit,
      resetForm,

      pondController,
    }
  },
}
</script>

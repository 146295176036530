export default id => ({
  namespaced: true,
  state: () => ({
    id,
    data: null,
    loading: true,
    creating: false,
    updating: false,
  }),
  getters: {
    id: state => state.id,
    data: state => state.data,
    loading: state => state.loading,
    updating: state => state.updating,
  },
  mutations: {
    INIT(state, payload) {
      state.id = payload
    },
    SET_DATA(state, payload) {
      state.data = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_UPDATING(state, payload) {
      state.updating = payload
    },
  },
  actions: {
    init({ commit }, payload) {
      commit('INIT', payload)
    },

    fetchData({ commit }) {
      commit('SET_LOADING', true)

      const { _vm: vm } = this

      vm.$http
        .get(`/v1/tips/${id}`)
        .then(res => {
          commit('SET_DATA', res.data?.data?.tip)
        })
        .finally(() => commit('SET_LOADING', false))
    },

    updateData({ commit }, formData) {
      commit('SET_UPDATING', true)

      const { _vm: vm } = this

      return new Promise((resolve, reject) => {
        vm.$http
          .post(`/v1/tips/${id}`, formData)
          .then(res => {
            commit('SET_DATA', res.data?.data?.tip)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => commit('SET_UPDATING', false))
      })
    },
  },
})
